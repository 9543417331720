import {
  VuexModule,
  Module,
  Action,
  Mutation,
  getModule
} from 'vuex-module-decorators'
import store from '@/store'
import token from '@/plugin/token'
import request from '@/plugin/request';

export interface IUserState {

  // 权限信息
  roles: string[];
  access: string[]

  // 用户信息
  corpid: string;
  id: string;
  name: string;
  avatar: string;
  mobile: string;

}

@Module({ dynamic: true, store, name: 'user' })
class User extends VuexModule implements IUserState {

  // 权限信息
  public roles: string[] = [];
  public access: string[] = [];

  // 用户信息
  public corpid = '';
  public id = '';
  public name = '';
  public avatar = '';
  public mobile = '';

  @Mutation
  private SET_USERINFO (userInfo: any) {

    this.corpid = userInfo.corpid
    this.id = userInfo.id
    this.name = userInfo.name
    this.avatar = userInfo.avatar
    this.mobile = userInfo.mobile

    this.roles = userInfo.roles
  }

  @Action({ rawError: true })
  public async login (userInfo: any) {
    return new Promise((resolve, reject) => {
      request('/notify/auth/work/login', userInfo, { method: 'post' }).then(response => {
        token.set(response.result.token)
        resolve(response.result.token)
      }).catch(error => { reject(error) })
    })
  }

  @Action({ rawError: true })
  public async GetUserInfo () {
    // 获取用户信息

    const response = await request('/notify/auth/userinfo', {}, { method: 'get' })

    this.SET_USERINFO(response.result)


    // const { data, dict } = await postData('/auth/userInfo')

    // DictModule.SET_DICT(dict)
    // // console.log("用户信息", data);
    // if (!data) {
    //   throw Error('Verification failed, please Login again.')
    // }

    // this.SET_USERINFO(data)
  }

  @Action({ rawError: true })
  public LogOut () {    // 注销

    token.remove()

  }

}

// getModule 得到一个类型安全的store，module必须提供name属性
export const UserModule = getModule(User)
