
import { defineComponent, ref } from 'vue';
import { ElConfigProvider } from 'element-plus'
import zhCn from 'element-plus/lib/locale/lang/zh-cn'
export default defineComponent({
    components: {
        [ElConfigProvider.name]: ElConfigProvider,
    },
    setup () {

        const locale = zhCn

        return {
            locale
        }

    }

});
