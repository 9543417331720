
import { defineComponent, ref } from 'vue';
import { APPModule } from '@/store/modules/app';
export default defineComponent({
    components: {

    },
    setup () {

        return {
            copyright: APPModule.copyright,
        }
    }
});
