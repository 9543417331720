<template>
    <el-config-provider :locale="locale">
        <router-view />
    </el-config-provider>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { ElConfigProvider } from 'element-plus'
import zhCn from 'element-plus/lib/locale/lang/zh-cn'
export default defineComponent({
    components: {
        [ElConfigProvider.name]: ElConfigProvider,
    },
    setup () {

        const locale = zhCn

        return {
            locale
        }

    }

});
</script>

<style lang="scss">
body {
    font-family: 'Microsoft YaHei';
}

.dev1 {
    background-color: #409eff;
}

.dev2 {
    background-color: #67c23a;
}

.dev3 {
    background-color: #e6a23c;
}

.dev4 {
    background-color: #f56c6c;
}

.dev5 {
    background-color: #909399;
}

body {
    margin: 0;
}
</style>
