<template>
    <el-footer height="40" style="color:#FFFFFF">Copyright @ {{ copyright }}</el-footer>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { APPModule } from '@/store/modules/app';
export default defineComponent({
    components: {

    },
    setup () {

        return {
            copyright: APPModule.copyright,
        }
    }
});
</script>


<style>
.el-footer {
    font-size: 13px;
    background-color: rgb(84, 92, 100);
    color: #333;
    text-align: center;
    line-height: 40px;
}
</style>