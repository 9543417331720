import Layout from '@/views/Layout/Index.vue'
import { RouteRecordRaw } from 'vue-router'

export default <RouteRecordRaw>{
  path: '/NotifyAssistant',
  component: Layout,
  redirect: '/Home',
  name: 'NotifyAssistant',
  meta: { title: '通知助手', icon: 'lock' },
  children: [
    {
      path: '/NotifyAssistant/Robot',
      component: () => import(/* webpackChunkName: "NotifyAssistant" */ '@/views/NotifyAssistant/Robot/Index.vue'),
      meta: { title: '机器人管理', icon: 'el-icon-menu', roles: [] }
    },
    {
      path: '/NotifyAssistant/Group',
      component: () => import(/* webpackChunkName: "NotifyAssistant" */ '@/views/NotifyAssistant/Group/Index.vue'),
      meta: { title: '成员分组', icon: 'el-icon-user', roles: [] }
    },
    {
      path: '/NotifyAssistant/Schedule',
      component: () => import(/* webpackChunkName: "NotifyAssistant" */ '@/views/NotifyAssistant/Schedule/Index.vue'),
      meta: { title: '定时发送', icon: 'el-icon-alarm-clock', roles: [] }
    }
  ]
}