
import { defineComponent, ref, computed } from 'vue';
import { useRoute, useRouter } from "vue-router"
import { APPModule } from '@/store/modules/app';
import { UserModule } from '@/store/modules/user';
import { PermissionModule } from '@/store/modules/permission'
import linq from 'linq'
export default defineComponent({
    components: {

    },
    setup () {

        const route = useRoute();
        const router = useRouter();

        const routes = computed(() => {
            let result = PermissionModule.routes.filter(r => !r.meta?.hidden && r.children && r.children.length > 0)
            return result;
        })

       

        const avatar = computed(() => UserModule.avatar)

        const activeIndex = computed(() => {
            const { matched } = route
            let path = matched[0].path
            if (!path) { path = matched[1].path }
            APPModule.SET_NAVBAR_ACTIVE(path)
            return path
        })

        // const activeIndex = ref('1');
        const handleSelect = (key: any, keyPath: any) => {
            APPModule.SET_NAVBAR_ACTIVE(key)

            let result = PermissionModule.routes.filter(r => !r.meta?.hidden && r.children && r.children.length > 0)
            const menu = linq.from(result).where((f: any) => f.path === key).firstOrDefault()

            if (menu?.children && menu.children.length > 0) {
                if (menu?.children[0].path === route.path) { return }
                router.push({ path: menu?.children[0].path })
            }
        };

        const handleAvatar = (command: string) => {
            switch (command) {
                case 'logout':
                    UserModule.LogOut()
                    router.push({ path: '/Login' })
                    break;
            }
        }

        return {
            routes: routes,
            shortTitle: APPModule.shortTitle,
            activeIndex,
            handleSelect,
            handleAvatar,
            avatar
        }

    }
});
