const tokenKey = 'meatou-token'

function get () {
  return sessionStorage.getItem(tokenKey)
}

function set (token: string) {
  return sessionStorage.setItem(tokenKey, token)
}

function remove () {
  return sessionStorage.removeItem(tokenKey)
}

export default {
  get,
  set,
  remove
}